import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { Auth0Audience } from './helpers'

/**
 * A simple get fetcher
 *
 * @param url - The URL to query
 * @param token - an Auth0 token (excluding "Bearer")
 */
export async function getFetcher(
  url: string,
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
): Promise<Response> {
  return new Promise<Response>((resolve, reject) => {
    const options: GetTokenSilentlyOptions = {
      audience: Auth0Audience(),
    }
    const { audience, scope } = options
    getAccessTokenSilently({ audience, scope }).then((token) => {
      if (!url) reject()
      fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (!res.ok) {
          res.json().then((errorCause) => {
            const error = new DataFetchError(
              'An error occurred while fetching the data',
              errorCause.toString()
            )
            throw error
          })
        } else {
          resolve(res)
        }
      })
    })
  })
}

export class DataFetchError extends Error {
  constructor(message: string, cause: Record<string, any>) {
    super()
    this.message = message
    this.cause = cause
  }
}
