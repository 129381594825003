import Select from 'react-select'
import styled from 'styled-components'
import { GeckoTheme } from 'gecko-ui'
import React, { useState, FC } from 'react'

import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons'
import { RepairStatValues } from '../../util/RepairStats'
import { useParams } from 'react-router-dom'

const Container = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 30%;
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  justify-content: space-between;
`

const Padding = styled.div`
  height: 100%;
  aspect-ratio: 1/2;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`

const SelectContainer = styled.div`
  width: 80%;
`

export interface ToggleableSelectProps {
  buttonSelection: Array<'repairs' | 'critical tube ft' | 'cad'>
  setButtonSelection: React.Dispatch<
    React.SetStateAction<Array<'repairs' | 'critical tube ft' | 'cad'>>
  >
  repairSelected: RepairStatValues
  setRepairSelected: React.Dispatch<React.SetStateAction<RepairStatValues>>
}

export const ToggleableSelect: FC<ToggleableSelectProps> = (props) => {
  let { urlSuffix } = useParams()
  urlSuffix = urlSuffix as string

  const buttonSelectionCopy = structuredClone(props.buttonSelection)

  const options = [
    { value: 'base', label: '5 Year' },
    { value: 'high', label: '10 Year' },
  ]
  return (
    <Container
      color={
        buttonSelectionCopy.includes('repairs')
          ? GeckoTheme.colors.gecko_brand['600_base']
          : GeckoTheme.colors.gray['200_white']
      }>
      <Padding
        onClick={() => {
          if (props.buttonSelection.includes('repairs')) {
            let buttonSelectionCopy = structuredClone(props.buttonSelection)
            buttonSelectionCopy = buttonSelectionCopy.filter((entry) => {
              return entry !== 'repairs'
            })
            props.setButtonSelection(buttonSelectionCopy)
          } else {
            const buttonSelectionCopy = structuredClone(props.buttonSelection)
            buttonSelectionCopy.push('repairs')
            props.setButtonSelection(buttonSelectionCopy)
          }
        }}>
        {buttonSelectionCopy.includes('repairs') ? (
          <CheckCircledIcon color={GeckoTheme.colors.gray['200_white']} />
        ) : (
          <CrossCircledIcon />
        )}
      </Padding>
      <SelectContainer>
        <Select
          options={options}
          defaultValue={options[0]}
          menuPlacement={'top'}
          isSearchable={false}
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          required={true}
          onChange={(newValue) => {
            props.setRepairSelected(newValue!.value as RepairStatValues)
          }}
        />
      </SelectContainer>
    </Container>
  )
}
