import { getFetcher } from './RequestFactory'
import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { fdeBackendUrl } from './helpers'

const urlBackend = fdeBackendUrl()
// const urlBackend = 'http://0.0.0.0:8080/api/v1'

/**
 * Fetch a Rolodex-File through the FDE-Backend.
 *
 * Because the FDE-Backend will always be running with "sufficient" WiFi, GCS calls won't time out and spin forever.
 * Then, once the backend gets the file, it can gzip compress it and forward the result here.
 */
export async function getRolodexFileThroughFDEBackend(
  entryPath: string,
  subPath: string,
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
) {
  const url = `${urlBackend}/boilerview/fetch-rolodex-file?entry_path=${entryPath}&sub_path=${subPath}`
  const res = await getFetcher(url, getAccessTokenSilently)
  return JSON.parse(await res.json())
}
