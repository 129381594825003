const { REACT_APP_ENVIRONMENT } = process.env

/*
DON'T PUT SENSITIVE VALUES IN THIS FILE
 */

const PROD_ENV = 'prod'

const AUTH0_CLIENT_ID_DEV = 'YGv1Ejs3O7jFsYqD15G4EQ0YKyYpWq6i'
const AUTH0_CLIENT_ID_PROD = '6gF7o1caYueRxcduJqZVNz4wTm3iNiHo'

export const Auth0ClientId = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_CLIENT_ID_PROD : AUTH0_CLIENT_ID_DEV
}

const AUTH0_URL_DEV = 'login.dev.geckorobotics.com'
const AUTH0_URL_PROD = 'geckorobotics.us.auth0.com'

export const Auth0Url = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_URL_PROD : AUTH0_URL_DEV
}

const OBJECT_SERVICE_URL_DEV = 'https://object-service.dev.cloud.geckorobotics.com/api/v1'
const OBJECT_SERVICE_URL_PROD = 'https://object-service.cloud.geckorobotics.com/api/v1'

export const ObjectServiceUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? OBJECT_SERVICE_URL_PROD
    : OBJECT_SERVICE_URL_DEV
}

const ASSET_FIX_URL_DEV = 'https://asset-fix.dev.cloud.geckorobotics.com'
const ASSET_FIX_URL_PROD = 'https://asset-fix.cloud.geckorobotics.com'

export const AssetFixUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? ASSET_FIX_URL_PROD : ASSET_FIX_URL_DEV
}

const FDE_BACKEND_URL_DEV = 'https://fde-backend.dev.cloud.geckorobotics.com/api/v1'
const FDE_BACKEND_URL_PROD = 'https://fde-backend.cloud.geckorobotics.com/api/v1'
export const fdeBackendUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? FDE_BACKEND_URL_PROD : FDE_BACKEND_URL_DEV
}

const AUTH0_AUDIENCE_DEV = 'dev.cloud.geckorobotics.com'
const AUTH0_AUDIENCE_PROD = 'cloud.geckorobotics.com'

export const Auth0Audience = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_AUDIENCE_PROD : AUTH0_AUDIENCE_DEV
}

const ROLODEX_URL_DEV = 'https://rolodex.dev.cloud.geckorobotics.com/api/v2'
const ROLODEX_URL_PROD = 'https://rolodex.cloud.geckorobotics.com/api/v2'

export const rolodexUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? ROLODEX_URL_PROD : ROLODEX_URL_DEV
}
