import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Layout, GeckoTheme } from 'gecko-ui'

import React, { Suspense } from 'react'
import styled from 'styled-components'

import Profile from './Profile'
import { useAuth0 } from '@auth0/auth0-react'
import { Experience } from './Experience'
import { SidebarContent } from '../components/misc/SidebarContent'
import { NoMatch } from './NoMatch'

const ErrorBoundaryBox = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 40rem) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (min-width: 40rem) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const ErrorBoundaryStyled = styled.div`
  text-align: center;
`

const ErrorBoundaryTextStyled = styled.h3`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: ${GeckoTheme.colors.slate[900]};
`
const ErrorBoundarySpacing = styled.div`
  margin-top: 1.5rem;
`

const ErrorButtonStyled = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-width: 0.0625rem;
  border-color: transparent;
  border-radius: 0.375rem;
  width: 5rem;
  height: 2.5rem;
  color: ${GeckoTheme.colors.gray['200_white']};
  background-color: ${GeckoTheme.colors.gecko_brand['600_base']};
  &:hover {
    background-color: ${GeckoTheme.colors.gecko_brand[700]};
  }
  &:focus {
    background-color: ${GeckoTheme.colors.gecko_brand[500]};
    outline: 0.125rem solid transparent;
    outline-offset: 0.125rem;
  }
`

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <ErrorBoundaryStyled role='alert'>
      <ErrorBoundaryTextStyled>There was an error:</ErrorBoundaryTextStyled>
      {/* @ts-ignore */}
      <ErrorBoundaryTextStyled>{error?.message?.message}</ErrorBoundaryTextStyled>
      <ErrorBoundarySpacing>
        <ErrorButtonStyled onClick={resetErrorBoundary}>Go back</ErrorButtonStyled>
      </ErrorBoundarySpacing>
    </ErrorBoundaryStyled>
  )
}

const LoggedInContent = () => {
  const navigate = useNavigate()
  const { user, logout, isLoading, isAuthenticated } = useAuth0()

  return (
    <Layout
      headerText='Boiler View'
      withSidebar={false}
      loginDropdown={true}
      sidebarContent={<SidebarContent />}
      darkMode>
      <ErrorBoundaryBox>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            navigate('/')
            // reset the state of your app so the error doesn't happen again
          }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path='/profile' element={<Profile />} />
              <Route path={'/:urlSuffix/demo'} element={<Experience isDemo={true} />} />
              <Route path={'/:urlSuffix'} element={<Experience isDemo={false} />} />
              <Route path='*' element={<NoMatch />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </ErrorBoundaryBox>
    </Layout>
  )
}

export default LoggedInContent
