import critical_bin_fig from '../data/figfile.json'
import { repairStats, RepairStatValues } from './RepairStats'
import duke_scenario_trace from '../data/duke_scenario.json'
import duke_repairs from '../data/duke_repairs.json'

export function getTrace(
  traceData: Array<Record<string, any>>,
  traceType: Array<'repairs' | 'critical tube ft' | 'cad'>,
  boilerCad: Array<Record<string, any>>,
  repairSelected: RepairStatValues,
  isDuke: boolean = false
) {
  if (traceData.length === 0) {
    return []
  }

  const fullTrace = [structuredClone(traceData[0])]

  if (traceType.includes('repairs')) {
    if (isDuke) {
      duke_repairs.forEach((trace: Record<string, any>) => {
        trace['marker'] = { showscale: false }
        trace['lighting'] = {
          ambient: 1,
          diffuse: 0,
          roughness: 1,
          specular: 0,
          fresnel: 0,
        }
      })
      fullTrace.push(...duke_repairs)
    } else {
      repairStats[repairSelected].repair_traces.forEach((trace: Record<string, any>) => {
        trace['marker'] = { showscale: false }
        trace['lighting'] = {
          ambient: 1,
          diffuse: 0,
          roughness: 1,
          specular: 0,
          fresnel: 0,
        }
      })
      fullTrace.push(...repairStats[repairSelected].repair_traces)
    }
  }

  if (traceType.includes('critical tube ft')) {
    const scenarioTraces = isDuke ? duke_scenario_trace : critical_bin_fig.data
    scenarioTraces.forEach((trace: Record<string, any>) => {
      trace['marker'] = { showscale: false }
      trace['lighting'] = {
        ambient: 1,
        diffuse: 0,
        roughness: 1,
        specular: 0,
        fresnel: 0,
      }
    })
    fullTrace.push(...scenarioTraces)
  }
  if (traceType.includes('cad') && boilerCad.length > 0) {
    fullTrace.push(boilerCad[0])
  }

  return fullTrace
}

// -------------------------------------------------------------------------------------------------------------------

export const COLOR_SCALE = [
  [0, '#c9c9c9'],
  [0.005, '#c9c9c9'],
  [0.005, 'rgba(194,0,50,1)'],
  [0.5, 'rgba(255,193,7,1)'],
  [0.995, 'rgba(0,105,103,1)'],
  [0.995, '#c9c9c9'],
  [1, '#c9c9c9'],
]

export const RED_COLOR_SCALE = [
  [0, '#c9c9c9'],
  [0.005, '#c9c9c9'],
  [0.005, 'rgba(194,0,50,1)'],
  [0.5, 'rgba(194,0,50,1)'],
  [0.995, 'rgba(194,0,50,1)'],
  [0.995, '#c9c9c9'],
  [1, '#c9c9c9'],
]
