import React from 'react'

import { Button, GeckoTheme } from 'gecko-ui'

import { ExternalLinkIcon } from '@radix-ui/react-icons'

import {
  StatsDiv,
  StatsContainer,
  TopSection,
  StatsTitle,
  StatsSubtitle,
  MiddleSection,
  TopBoxGridContainer,
  GridItemFlex,
  StatsTopboxTitle,
  StatsValues,
  StatsTopboxSubtitles,
  BottomSection,
  StatsMiddleAndBotBoxDescription,
  MiddleGridHolder,
  MiddleBoxGridContainer,
  GridItem,
  StatsIcon,
  StatsMiddleboxTitles,
  StatsMiddleboxSubtitles,
  GeckoAdvantageDiv,
  BottomGridHolder,
  BottomBoxGridContainer,
  StatsBottomboxTitles,
  ButtonsDiv,
  RepairsButton,
  ViewInspectionButton,
  ButtonContainer,
  TopBoxSubSubTitles,
  CurserChangeDiv,
  TitleContainer,
  ColorChangeLink,
  ColoredText,
} from './style'

import money_image from '../../images/Funds_Icon_Light_Green.png'
import tools_image from '../../images/maintanence_transparent.png'
import { MiddleGreenBoxes } from './MiddleGreenBoxes'
import { TimelineBox } from './TimelineBox'
import { AssetFixUrl } from '../../api/helpers'
import { ToggleableSelect } from './ToggleableSelect'
import { repairStats, RepairStatValues } from '../../util/RepairStats'
import { useParams } from 'react-router-dom'

//TODO: add a function to add commas to the numbers and convert to strings
export interface StatsProps {
  buttonSelection: Array<'repairs' | 'critical tube ft' | 'cad'>
  setButtonSelection: React.Dispatch<
    React.SetStateAction<Array<'repairs' | 'critical tube ft' | 'cad'>>
  >
  repairSelected: RepairStatValues
  setRepairSelected: React.Dispatch<React.SetStateAction<RepairStatValues>>
  isDemo: boolean
}

export const Stats: React.FC<StatsProps> = (props) => {
  let url = AssetFixUrl() + '/inspection/waterwalls-c88f18'

  let { urlSuffix } = useParams()
  urlSuffix = urlSuffix as string

  if (props.isDemo) {
    url = url + '/demo'
  }

  const [showTimeline, setShowTimeline] = React.useState<boolean>(false)

  return (
    <StatsDiv>
      <StatsContainer>
        {/*----------------------------------------------------*/}
        <TopSection>
          <TitleContainer>
            <StatsTitle>{!props.isDemo ? 'Unit 3 Boiler' : 'PC Boiler'}</StatsTitle>
            <ColorChangeLink href={url} target='_blank'>
              <ExternalLinkIcon />
            </ColorChangeLink>
          </TitleContainer>
          <StatsSubtitle>
            <span style={{ color: 'white' }}>Site:</span>{' '}
            {!props.isDemo ? 'Petersburg' : 'Generating Station'}
          </StatsSubtitle>

          <StatsSubtitle>
            <span style={{ color: 'white' }}> Planned Retirement Date: </span>
            {' 2035'}
          </StatsSubtitle>
          <StatsSubtitle>
            <span style={{ color: 'white' }}>Inspection Completion Date:</span>{' '}
            {'11/11/2022'}
          </StatsSubtitle>
        </TopSection>
        {/*---------------------------------------------------*/}
        {!showTimeline ? (
          <MiddleGreenBoxes repairSelected={props.repairSelected} />
        ) : (
          <TimelineBox isDemo={props.isDemo} />
        )}
        {/*------------------------------------------------------------------*/}
        <GeckoAdvantageDiv>
          <StatsMiddleAndBotBoxDescription>
            Program Impact Over Next 5 Years
          </StatsMiddleAndBotBoxDescription>
          <BottomGridHolder>
            <BottomBoxGridContainer>
              <GridItemFlex>
                <StatsBottomboxTitles>Outages Avoided</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>EFOF Improvement</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>At Risk Labor</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>CO2e Avoided</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {' '}
                  <CurserChangeDiv onClick={() => setShowTimeline(!showTimeline)}>
                    <ColoredText color={GeckoTheme.colors.warning['400_base']}>
                      {repairStats[props.repairSelected].outages_avoided}
                    </ColoredText>
                  </CurserChangeDiv>
                </StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {repairStats[props.repairSelected].efof_improvement} bps
                </StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {repairStats[props.repairSelected].man_hours_avoided.toLocaleString(
                    'en-US'
                  )}{' '}
                  Hours
                </StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {repairStats[props.repairSelected].co2e_avoided} K Tons
                </StatsValues>
              </GridItemFlex>
            </BottomBoxGridContainer>
          </BottomGridHolder>
        </GeckoAdvantageDiv>
        {/*--------------------------------*/}
        <ButtonContainer>
          <ToggleableSelect
            buttonSelection={props.buttonSelection}
            setButtonSelection={props.setButtonSelection}
            repairSelected={props.repairSelected}
            setRepairSelected={props.setRepairSelected}
          />
          <Button
            label={'Outages'}
            buttonType={
              props.buttonSelection.includes('critical tube ft') ? 'primary' : 'secondary'
            }
            onClick={() => {
              if (props.buttonSelection.includes('critical tube ft')) {
                let buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy = buttonSelectionCopy.filter((entry) => {
                  return entry !== 'critical tube ft'
                })
                props.setButtonSelection(buttonSelectionCopy)
              } else {
                const buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy.push('critical tube ft')
                props.setButtonSelection(buttonSelectionCopy)
              }
            }}
            style={{ width: '30%', padding: '.5rem' }}
          />
          <Button
            label={'Model'}
            buttonType={props.buttonSelection.includes('cad') ? 'primary' : 'secondary'}
            onClick={() => {
              if (props.buttonSelection.includes('cad')) {
                let buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy = buttonSelectionCopy.filter((entry) => {
                  return entry !== 'cad'
                })
                props.setButtonSelection(buttonSelectionCopy)
              } else {
                const buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy.push('cad')
                props.setButtonSelection(buttonSelectionCopy)
              }
            }}
            style={{ width: '30%', padding: '.5rem' }}
          />
        </ButtonContainer>
      </StatsContainer>
    </StatsDiv>
  )
}
