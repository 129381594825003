import styled from 'styled-components'
import { Plot } from '../components/plotting/Plot'
import { Stats } from '../components/stats/Stats'
import React, { FC } from 'react'
import { RepairStatValues } from '../util/RepairStats'
import { useParams } from 'react-router-dom'
import { DukeStats } from '../components/stats/duke/DukeStats'
import { GIBSON_U1_SLUG } from '../util/HardCodedSlugs'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`

interface ExperienceProps {
  isDemo: boolean
}

export const Experience: FC<ExperienceProps> = (props) => {
  const [buttonSelection, setButtonSelection] = React.useState<
    Array<'repairs' | 'critical tube ft' | 'cad'>
  >([])

  let { urlSuffix } = useParams()
  urlSuffix = urlSuffix as string
  const [repairSelected, setRepairSelected] = React.useState<RepairStatValues>('base')

  return (
    <Container>
      <Plot buttonSelection={buttonSelection} repairSelected={repairSelected} />
      {urlSuffix === GIBSON_U1_SLUG ? (
        <DukeStats
          buttonSelection={buttonSelection}
          setButtonSelection={setButtonSelection}
        />
      ) : (
        <Stats
          buttonSelection={buttonSelection}
          setButtonSelection={setButtonSelection}
          repairSelected={repairSelected}
          setRepairSelected={setRepairSelected}
          isDemo={props.isDemo}
        />
      )}
    </Container>
  )
}
