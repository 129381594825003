import styled from 'styled-components'
import { Timeline } from '../plotting/Timeline'
import React, { FC, useCallback, useEffect, useRef } from 'react'
import { ElementResizeListener } from '../misc/ElementResizeListener'

export const Container = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  width: 90%;
  background-color: rgb(0, 134, 119);
  border-radius: 1rem;
  border: 0.1rem solid white;
`

interface TimelineBoxProps {
  isDemo: boolean
}

export const TimelineBox: FC<TimelineBoxProps> = (props) => {
  const timeline_box: React.RefObject<HTMLDivElement> = useRef(null)
  useEffect(() => {
    adaptResize()
  })
  const [width, setWidth] = React.useState(475)
  const adaptResize = useCallback(() => {
    if (timeline_box.current) {
      const elmRect = timeline_box.current.getBoundingClientRect()
      setWidth(elmRect.width)
    }
  }, [])
  return (
    <Container ref={timeline_box}>
      <ElementResizeListener onResize={adaptResize} />
      <Timeline width={width} isDemo={props.isDemo} />
    </Container>
  )
}
