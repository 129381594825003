import React from 'react'
import { ReactComponent as GeckoLogo } from 'assets/gecko-logo.svg'
import styled from 'styled-components'

const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const LogoStyled = styled.div`
  margin-bottom: 1rem;
  width: 8rem;
  height: 8rem;
`

function LoadingContent() {
  return (
    <LogoBox>
      <LogoStyled>
        <GeckoLogo /> Loading(...)
      </LogoStyled>
    </LogoBox>
  )
}

export default LoadingContent
