import React from 'react'

import { Button, GeckoTheme } from 'gecko-ui'

import { ExternalLinkIcon } from '@radix-ui/react-icons'

import {
  StatsDiv,
  StatsContainer,
  TopSection,
  StatsTitle,
  StatsSubtitle,
  MiddleSection,
  TopBoxGridContainer,
  GridItemFlex,
  StatsTopboxTitle,
  StatsValues,
  StatsTopboxSubtitles,
  BottomSection,
  StatsMiddleAndBotBoxDescription,
  MiddleGridHolder,
  MiddleBoxGridContainer,
  GridItem,
  StatsIcon,
  StatsMiddleboxTitles,
  StatsMiddleboxSubtitles,
  GeckoAdvantageDiv,
  BottomGridHolder,
  BottomBoxGridContainer,
  StatsBottomboxTitles,
  ButtonsDiv,
  RepairsButton,
  ViewInspectionButton,
  ButtonContainer,
  TopBoxSubSubTitles,
  CurserChangeDiv,
  TitleContainer,
  ColorChangeLink,
  ColoredText,
} from './DukeStyledComponents'

import { MiddleGreenBoxes } from '../MiddleGreenBoxes'
import { TimelineBox } from '../TimelineBox'
import { AssetFixUrl } from '../../../api/helpers'
import { ToggleableSelect } from '../ToggleableSelect'
import { repairStats, RepairStatValues } from '../../../util/RepairStats'
import { useParams } from 'react-router-dom'
import { DukeMiddleGreenBoxes } from './DukeMiddleGreenBoxes'

export interface DukeStatsProps {
  buttonSelection: Array<'repairs' | 'critical tube ft' | 'cad'>
  setButtonSelection: React.Dispatch<
    React.SetStateAction<Array<'repairs' | 'critical tube ft' | 'cad'>>
  >
}

export const DukeStats: React.FC<DukeStatsProps> = (props) => {
  let url = AssetFixUrl() + '/inspection/waterwalls-217aec'

  let { urlSuffix } = useParams()
  urlSuffix = urlSuffix as string

  const [showTimeline, setShowTimeline] = React.useState<boolean>(false)

  return (
    <StatsDiv>
      <StatsContainer>
        {/*----------------------------------------------------*/}
        <TopSection>
          <TitleContainer>
            <StatsTitle>Unit 1 Boiler</StatsTitle>
            <ColorChangeLink href={url} target='_blank'>
              <ExternalLinkIcon />
            </ColorChangeLink>
          </TitleContainer>
          <StatsSubtitle>
            <span style={{ color: 'white' }}>Site:</span> Gibson
          </StatsSubtitle>

          <StatsSubtitle>
            <span style={{ color: 'white' }}> Planned Retirement Date: </span>
            {' 2035'}
          </StatsSubtitle>
          <StatsSubtitle>
            <span style={{ color: 'white' }}>Inspection Completion Date:</span>{' '}
            {'10/4/2022'}
          </StatsSubtitle>
        </TopSection>
        {/*---------------------------------------------------*/}
        <DukeMiddleGreenBoxes />
        {/*------------------------------------------------------------------*/}
        <GeckoAdvantageDiv>
          <StatsMiddleAndBotBoxDescription>
            Program Impact Over Next 5 Years
          </StatsMiddleAndBotBoxDescription>
          <BottomGridHolder>
            <BottomBoxGridContainer>
              <GridItemFlex>
                <StatsBottomboxTitles>Predicted Outages</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>Margin Impact</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>CO2e Impact</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {' '}
                  <ColoredText color={GeckoTheme.colors.gray['200_white']}>
                    3-5
                  </ColoredText>
                </StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>$1.1M-$12M</StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>90K-150K Tons</StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>Man Hours Avoided</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>EFOR Impact</StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsBottomboxTitles>
                  {'Service Hrs To Failure (From Insp)'}
                </StatsBottomboxTitles>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {' '}
                  <ColoredText color={GeckoTheme.colors.gray['200_white']}>
                    744-1,240
                  </ColoredText>
                </StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {' '}
                  <ColoredText color={GeckoTheme.colors.gray['200_white']}>
                    238 BPS
                  </ColoredText>
                </StatsValues>
              </GridItemFlex>
              <GridItemFlex>
                <StatsValues>
                  {' '}
                  <ColoredText color={GeckoTheme.colors.gray['200_white']}>
                    13,656
                  </ColoredText>
                </StatsValues>
              </GridItemFlex>
            </BottomBoxGridContainer>
          </BottomGridHolder>
        </GeckoAdvantageDiv>
        {/*--------------------------------*/}
        <ButtonContainer>
          <Button
            label={'Repairs'}
            buttonType={
              props.buttonSelection.includes('repairs') ? 'primary' : 'secondary'
            }
            onClick={() => {
              if (props.buttonSelection.includes('repairs')) {
                let buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy = buttonSelectionCopy.filter((entry) => {
                  return entry !== 'repairs'
                })
                props.setButtonSelection(buttonSelectionCopy)
              } else {
                const buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy.push('repairs')
                props.setButtonSelection(buttonSelectionCopy)
              }
            }}
            style={{ width: '30%', padding: '.5rem' }}
          />
          <Button
            label={'Outages'}
            buttonType={
              props.buttonSelection.includes('critical tube ft') ? 'primary' : 'secondary'
            }
            onClick={() => {
              if (props.buttonSelection.includes('critical tube ft')) {
                let buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy = buttonSelectionCopy.filter((entry) => {
                  return entry !== 'critical tube ft'
                })
                props.setButtonSelection(buttonSelectionCopy)
              } else {
                const buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy.push('critical tube ft')
                props.setButtonSelection(buttonSelectionCopy)
              }
            }}
            style={{ width: '30%', padding: '.5rem' }}
          />
          <Button
            label={'Model'}
            buttonType={props.buttonSelection.includes('cad') ? 'primary' : 'secondary'}
            onClick={() => {
              if (props.buttonSelection.includes('cad')) {
                let buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy = buttonSelectionCopy.filter((entry) => {
                  return entry !== 'cad'
                })
                props.setButtonSelection(buttonSelectionCopy)
              } else {
                const buttonSelectionCopy = structuredClone(props.buttonSelection)
                buttonSelectionCopy.push('cad')
                props.setButtonSelection(buttonSelectionCopy)
              }
            }}
            style={{ width: '30%', padding: '.5rem' }}
          />
        </ButtonContainer>
      </StatsContainer>
    </StatsDiv>
  )
}
