// import repairs_1 from '../data/repair_plan_1.json'
import repairs_2 from '../data/repair_plan_2.json'
import repairs_3 from '../data/repair_plan_3.json'

export const repairStats: RepairStats = {
  high: {
    failure_threshold_used: '0.110',
    outage_days: 14,
    total_repair_area: 2290,
    estimated_repair_cost: 2170000,
    sqft_overlay: 482,
    sqft_panels: 1808,
    cost_overlay: 360000,
    cost_panels: 1810000,
    outages_avoided: 18,
    efof_improvement: 390,
    man_hours_avoided: 3456,
    co2e_avoided: 555,
    repair_traces: repairs_3,
  },
  // aes: {
  //   failure_threshold_used: '.18/.165',
  //   outage_days: 8,
  //   total_repair_area: 973,
  //   estimated_repair_cost: 888755,
  //   sqft_overlay: 331,
  //   sqft_panels: 642,
  //   cost_overlay: 182050,
  //   cost_panels: 642000,
  //   outages_avoided: 79,
  //   efof_improvement: 6.7,
  //   man_hours_avoided: 50336,
  //   co2e_avoided: 2.4,
  //   repair_traces: repairs_1,
  // },
  base: {
    failure_threshold_used: '0.110',
    outage_days: 10,
    total_repair_area: 967,
    estimated_repair_cost: 926731,
    sqft_overlay: 159,
    sqft_panels: 808,
    cost_overlay: 87450,
    cost_panels: 808000,
    outages_avoided: 18,
    efof_improvement: 390,
    man_hours_avoided: 3456,
    co2e_avoided: 555,
    repair_traces: repairs_2,
  },
}

export type RepairStats = {
  [key in RepairStatValues]: {
    failure_threshold_used: string
    outage_days: number
    total_repair_area: number
    estimated_repair_cost: number
    sqft_overlay: number
    sqft_panels: number
    cost_overlay: number
    cost_panels: number
    outages_avoided: number
    efof_improvement: number
    man_hours_avoided: number
    co2e_avoided: number
    repair_traces: Array<Record<string, any>>
  }
}

export type RepairStatValues = string
