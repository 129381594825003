import styled from 'styled-components'
import { GeckoTheme } from 'gecko-ui'

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StatsDiv = styled.div`
  resize: horizontal;
  overflow: auto;
  opacity: 0.9;
  position: absolute;
  height: calc(100% - 7rem);
  min-width: 33%;
  max-width: 50%;
  background: ${GeckoTheme.colors.slate['800']};
  right: 1rem;
  bottom: 1rem;
  border-radius: 1rem;
  border: 0.1rem solid white;
  direction: rtl
`

export const StatsContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  direction: ltr
`

export const TopSection = styled.div`
  width: 90%;
  margin-top: 1rem;
  @media (max-width: 1200px) {
    margin-top: 0.5rem;
  }
`

export const MiddleSection = styled.div`
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 90%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 134, 119);
  border-radius: 1rem;
  border: 0.1rem solid white;
`

export const BottomSection = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 134, 119);
  border-radius: 1rem;
  border: 0.1rem solid white;
`

export const GeckoAdvantageDiv = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  width: 90%;
  background-color: rgb(0, 134, 119);
  border-radius: 1rem;
  border: 0.1rem solid white;
`

export const TopBoxGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
`

export const MiddleBoxGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  @media (max-width: 1200px) {
    padding: 0;
  }
`

export const MiddleGridHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const BottomBoxGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 0rem;
  @media (max-width: 1200px) {
    padding: 0;
  }
`

export const BottomGridHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GridItem = styled.div`
  text-align: center;
`

export const GridItemFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StatsIcon = styled.img`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-self: center;
  align-self: center;
`

export const RepairsButton = styled.button`
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-width: 1px;
  border-radius: 0.375rem;
  cursor: pointer;
  font-family: Poppins;
  transition-duration: 0.3s;
  transition-property: background-color, opacity;
  color: rgb(114, 114, 114);
  background-color: white;
  border-color: transparent;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  @media (max-width: 1200px) {
    font-size: 0.75rem;
  }
`

export const ViewInspectionButton = styled.button`
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-width: 1px;
  border-radius: 0.375rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  cursor: pointer;
  font-family: Poppins;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-color: transparent;
  background-color: rgb(0, 105, 103);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;
  @media (max-width: 1200px) {
    font-size: 0.75rem;
  }
`

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media (max-width: 1200px) {
    margin-bottom: 0.5rem;
  }
  @media (max-width: 1670px) {
    flex-direction: column;
    gap: 1rem;
  }
`
export const StatsTitle = styled.h1`
  font-size: 1.8rem;
  font-family: 'Poppins' sans-serf;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
  padding-right: 0.1rem;
  color: white;
  padding-bottom: 0.8rem;
  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
`

export const StatsSubtitle = styled.h2`
  width: 80%;
  font-size: 1.1rem;
  padding-bottom: 0rem;
  font-family: 'Poppins' sans-serf;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgb(162, 186, 195);
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`

export const StatsTopboxTitle = styled.p`
  font-size: 1.1rem;
  font-family: 'Poppins' sans-serf;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0;
  text-align: center;
  color: rgb(162, 186, 195);
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`

export const StatsValues = styled.p`
  font-size: 1.5rem;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0;
  color: white;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
`

export const StatsTopboxSubtitles = styled.p`
  font-size: 1.5rem;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0;
  color: rgb(162, 186, 195);
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`

export const StatsMiddleAndBotBoxDescription = styled.p`
  margin: 0.25rem;
  font-size: 1.2rem;
  color: ${GeckoTheme.colors.gray['200_white']};
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`

export const StatsMiddleboxTitles = styled.p`
  font-size: 0.9rem;
  font-family: 'Poppins' sans-serf;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0;
  color: rgb(162, 186, 195);
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`

export const StatsMiddleboxSubtitles = styled.p`
  font-size: 0.8rem;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0;
  color: rgb(162, 186, 195);
  @media (max-width: 1200px) {
    font-size: 0.75rem;
  }
`

export const StatsBottomboxTitles = styled.p`
  font-size: 1.1rem;
  font-family: 'Poppins' sans-serf;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 0;
  color: rgb(162, 186, 195);
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5rem;
`

export const TopBoxSubSubTitles = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  fontsize: 1.25rem;
  font-family: 'Poppins' sans-serf;
`

export const CurserChangeDiv = styled.div`
  :hover {
    cursor: context-menu;
  }
`

export const ColorChangeLink = styled.a`
  height: min-content;
  color: ${GeckoTheme.colors.gray['200_white']};
  :hover {
    color: ${GeckoTheme.colors.blue['500_base']};
  }
`

export const ColoredText = styled.div<{ color: string }>`
  color: ${(props) => props.color};
`
