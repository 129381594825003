import { GeckoTheme } from 'gecko-ui'

export const noActionTrace = {
  mode: 'lines',
  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 68, 80, 91, 96, 101, 115, 121, 132, 140, 154, 170, 183, 196],
  type: 'scatter',
  line: { dash: 'dash' },
  marker: { color: GeckoTheme.colors.warning['400_base'] },
  showlegend: true,
  name: 'Reactive'}

export const historicalTrace = {
  mode: 'lines',
  x: [
    '2010-09-07',
    '2010-12-29',
    '2011-01-10',
    '2011-03-26',
    '2011-05-28',
    '2011-06-04',
    '2011-07-17',
    '2011-08-11',
    '2011-11-19',
    '2012-01-20',
    '2012-02-27',
    '2012-04-04',
    '2012-07-26',
    '2012-10-02',
    '2013-04-05',
    '2013-09-11',
    '2013-10-31',
    '2013-11-25',
    '2013-12-20',
    '2014-01-02',
    '2014-01-14',
    '2014-01-27',
    '2014-03-05',
    '2014-03-18',
    '2014-03-30',
    '2014-05-07',
    '2014-06-13',
    '2014-10-04',
    '2014-11-26',
    '2014-12-30',
    '2015-06-11',
    '2015-12-03',
    '2016-05-26',
    '2016-12-12',
    '2017-02-12',
    '2017-06-30',
    '2017-11-02',
    '2018-05-21',
    '2018-10-30',
    '2019-10-03',
    '2020-01-23',
    '2020-02-05',
    '2020-04-20',
    '2020-09-17',
    '2021-02-14',
    '2021-06-19',
    '2021-10-22',
    '2022-03-11',
  ],
  y: [
    17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
    38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64,
  ],
  marker: { color: GeckoTheme.colors.warning['400_base'] },
  showlegend: false,
  name: 'historical',
}

export const base20Trace = {
  mode: 'lines',
  line: { dash: 'dash' },

  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0],
  type: 'scatter',
  name: '20%',
  marker: { color: GeckoTheme.colors.crimson['400'] },
  legendgrouptitle: { text: 'Base' },
  legendgroup: 'base',
}

export const base40Tracea = {
  mode: 'lines',
  legendgroup: 'base',
  line: { dash: 'dot' },

  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 66, 69, 70, 73, 82, 93, 102, 125, 154, 170, 183, 196],
  type: 'scatter',
  name: '40%',
  marker: { color: GeckoTheme.colors.crimson['600'] },
}

export const high20Trace = {
  mode: 'lines',
  legendgroup: 'high',
  line: { dash: 'dash' },

  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0, 66.0],
  type: 'scatter',
  name: '20%',
  legendgrouptitle: { text: 'High' },
  marker: { color: GeckoTheme.colors.blue['600'] },
}

export const high40Trace = {
  mode: 'lines',
  legendgroup: 'high',
  line: { dash: 'dot', width:3 },

  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 67, 68, 69, 70, 71, 72, 73, 74,75,76,77,78,79],
  type: 'scatter',
  name: '40%',
  marker: { color: GeckoTheme.colors.blue['300'] },
}

export const aesProcess20 = {
  mode: 'lines',
  legendgroup: 'aes',
  legendgrouptitle: { text: 'AES Process' },
  line: { dash: 'dash' },
  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 66, 79, 81, 83, 86, 91, 93, 103, 106, 111, 114, 117],
  marker: { color: GeckoTheme.colors.purple['400'] },
  type: 'scatter',
  name: '20%',
}

export const aesProcess40a = {
  name: '40%',
  legendgroup: 'aes',
  marker: { color: GeckoTheme.colors.purple['500_base'] },
  mode: 'lines',
  line: { dash: 'dot' },

  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 66, 78, 81, 90, 101, 113, 129, 140, 154, 170, 183, 196],
  type: 'scatter',
}

export const depreciatedTrace = {
  mode: 'lines',

  x: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035],
  y: [66, 80, 97, 99, 100, 104, 109, 115, 122, 126, 130, 134, 137],
  marker: { color: GeckoTheme.colors.warning['400_base'] },
  line: { dash: 'dot' },
  showlegend: false,
  name: 'predictions',
}

export const base40Trace = {
  mode: 'lines',
  line: { dash: 'dot' },

  legendgroup: 'base',
  marker: { color: GeckoTheme.colors.crimson['600'] },
  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 66, 69, 70, 73, 82, 93, 102, 125, 153, 177, 203, 225],
  type: 'scatter',
  name: '40%',
}

export const aesProcess40 = {
  name: '40%',
  line: { dash: 'dot' },

  mode: 'lines',
  legendgroup: 'aes',
  marker: { color: GeckoTheme.colors.purple['500_base'] },

  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 66, 78, 81, 90, 101, 113, 129, 140, 158, 177, 196, 209],
  type: 'scatter',
}

export const aesStandalone = {
  mode: 'lines',
  line: { dash: 'dot' },
  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 66, 74, 76, 78, 81, 86, 88, 98, 101, 106, 112, 117],
  marker: { color: GeckoTheme.colors.purple['500_base'] },
  type: 'scatter',
  name: 'AES',
}

export const baseStandalone = {
  mode: 'lines',
  line: { dash: 'dot', width:3},
  marker: { color: GeckoTheme.colors.crimson['600'] },
  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 67, 68, 69, 70, 71, 72, 73, 78, 84, 87, 95, 101],
  type: 'scatter',
  name: '5 Year',
}

export const highStandalone = {
  ...high40Trace,
  name: '10 Year',
  legendgroup: '',
}


export const currentTrendST = {
   mode: 'lines',
  line: { dash: 'dot',width:3 },
  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
  ],
  y:[ 64,  67 , 68 , 76 , 80 , 88],
  marker: { color: GeckoTheme.colors.purple['500_base'] },
  type: 'scatter',
  name: 'Trend',
legendgroup: 'aes'}

export const trendLongTerm = {
   mode: 'lines',
  line: { dash: 'dash',  width:3 },
  x: [


      2027,
      '2028-02-21',
      2029,
      '2030-02-21',
      2031,
      '2032-02-21',
      2033,
      '2034-02-21',
      2035

  ],
  y:[ 88, 92, 95, 99, 102, 106, 109, 113, 116],
  marker: { color: GeckoTheme.colors.purple['500_base']},
  type: 'scatter',
  name: 'Trend',
  showlegend: false,
  legendgroup: 'aes',
  }


export const demoStandalone = {
  mode: 'lines',
  line: { dash: 'dot' },
  x: [
    '2022-03-11',
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ],
  y: [64, 66, 66, 74, 76, 78, 81, 86, 88, 98, 101, 106, 112, 117],
  marker: { color: GeckoTheme.colors.purple['500_base'] },
  type: 'scatter',
  name: 'Default',
}
