// @ts-ignore
import Plotly from 'plotly.js/dist/plotly.js'

import React from 'react'
import { COLOR_SCALE, getTrace } from '../../util/TraceGetter'
import {
  getCadTraceFromBackend,
  getThicknessTraceFromBackend,
} from '../../api/TraceGetterInterface'
import { useAuth0 } from '@auth0/auth0-react'
import { RepairStatValues } from '../../util/RepairStats'
import { useParams } from 'react-router-dom'
import { GIBSON_U1_SLUG } from '../../util/HardCodedSlugs'

const NO_COLOR = 'rgba(0,0,0,0)'

const config = {
  displayModeBar: false,
}

export interface PlotProps {
  buttonSelection: Array<'repairs' | 'critical tube ft' | 'cad'>
  repairSelected: RepairStatValues
}

export const Plot: React.FC<PlotProps> = (props) => {
  const { getAccessTokenSilently } = useAuth0()
  let { urlSuffix } = useParams()
  urlSuffix = urlSuffix as string

  const isDuke = urlSuffix === GIBSON_U1_SLUG

  const [bigPlotData, setBigPlotData] = React.useState<Array<Record<string, any>>>([])
  const [boilerCad, setBoilerCad] = React.useState<Array<Record<string, any>>>([])

  const layout = {
    scene: {
      xaxis: { visible: true, showticklabels: false },
      yaxis: { visible: true, showticklabels: false },
      zaxis: { visible: true, showgrid: bigPlotData.length > 0, title: 'Elevation' },
    },
    xaxis: { visible: false },
    yaxis: { visible: false },
    showlegend: false,
    aspectmode: 'data',
    height: 800,
    width: 1150,
    margin: { l: 35, r: 20, t: 20, b: 0 },
    paper_bgcolor: NO_COLOR,
    plot_bgcolor: NO_COLOR,
    dragmode: 'turntable',
    annotations: bigPlotData.length === 0 && [
      {
        align: 'center',
        text: 'Generating Plot...',
        showarrow: false,
      },
    ],
  }

  React.useEffect(() => {
    Plotly.newPlot(
      'plot',
      getTrace(
        bigPlotData,
        props.buttonSelection,
        boilerCad,
        props.repairSelected,
        isDuke
      ),
      layout,
      config
    )
    getThicknessTraceFromBackend(urlSuffix, getAccessTokenSilently).then((data) => {
      const realCMax = urlSuffix === GIBSON_U1_SLUG ? 0.349 : 0.26152000000000003
      const realCMin = urlSuffix === GIBSON_U1_SLUG ? 0.086 : 0.10648
      data[0]['cmin'] = realCMin
      data[0]['cmax'] = realCMax
      data[0]['colorscale'] = COLOR_SCALE
      data[0]['showscale'] = false
      data[0]['lighting'] = {
        ambient: 1,
        diffuse: 0,
        roughness: 1,
        specular: 0,
        fresnel: 0,
      }
      data[0]['name'] = ''
      Plotly.relayout('plot', { annotations: [] })
      setBigPlotData(data)
    })
    getCadTraceFromBackend(urlSuffix, getAccessTokenSilently).then((data) => {
      data[0]['hoverinfo'] = 'skip'
      setBoilerCad(data)
    })
  }, [])

  React.useEffect(() => {
    if (bigPlotData.length === 0) {
      return
    }
    const plotFig: any = document.getElementById('plot')
    Plotly.deleteTraces(
      plotFig,
      Array.from(Array(plotFig.data.length), (_, i) => i)
    ).then(() => {
      Plotly.addTraces(
        plotFig,
        getTrace(
          bigPlotData,
          props.buttonSelection,
          boilerCad,
          props.repairSelected,
          isDuke
        )
      )
    })
  }, [props.buttonSelection, bigPlotData, boilerCad, props.repairSelected])

  return (
    <div>
      <div id={'plot'}></div>
    </div>
  )
}
