import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { getRolodexFileThroughFDEBackend } from './FDEBackendInterface'

const THICKNESS_TRACE_FILENAME = 'thickness_trace.json'
const CAD_TRACE_FILENAME = 'cad_trace.json'

export async function getThicknessTraceFromBackend(
  slug: string | undefined,
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
) {
  const fileContents = await getRolodexFileThroughFDEBackend(
    `boilerview-${slug}`,
    THICKNESS_TRACE_FILENAME,
    getAccessTokenSilently
  )
  return fileContents
}

export async function getCadTraceFromBackend(
  slug: string | undefined,
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
) {
  const fileContents = await getRolodexFileThroughFDEBackend(
    `boilerview-${slug}`,
    CAD_TRACE_FILENAME,
    getAccessTokenSilently
  )
  return fileContents
}
