import {
  BottomSection,
  GridItem,
  GridItemFlex,
  MiddleBoxGridContainer,
  MiddleGridHolder,
  MiddleSection,
  StatsIcon,
  StatsMiddleAndBotBoxDescription,
  StatsMiddleboxSubtitles,
  StatsMiddleboxTitles,
  StatsTopboxTitle,
  StatsValues,
  TopBoxGridContainer,
  TopBoxSubSubTitles,
} from './style'
import tools_image from '../../images/maintanence_transparent.png'
import money_image from '../../images/Funds_Icon_Light_Green.png'
import React from 'react'
import { repairStats, RepairStatValues } from '../../util/RepairStats'

export interface MiddleGreenBoxesProps {
  repairSelected: RepairStatValues
}

export const MiddleGreenBoxes: React.FC<MiddleGreenBoxesProps> = (props) => {
  return (
    <>
      <MiddleSection>
        <TopBoxGridContainer>
          <GridItemFlex>
            <StatsTopboxTitle>Total Readings Captured</StatsTopboxTitle>
          </GridItemFlex>
          <GridItemFlex>
            <StatsTopboxTitle>Lowest Reading Found</StatsTopboxTitle>
          </GridItemFlex>
          <GridItemFlex>
            <StatsTopboxTitle>Failure Threshold Used</StatsTopboxTitle>
          </GridItemFlex>
          <GridItemFlex>
            <StatsValues style={{ marginTop: '20px' }}>{'55.3 M'}</StatsValues>
          </GridItemFlex>
          <GridItemFlex>
            <StatsValues style={{ marginTop: '20px' }}>{'0.108'}</StatsValues>
          </GridItemFlex>
          <GridItemFlex>
            <StatsValues style={{ marginTop: '20px' }}>
              {repairStats[props.repairSelected].failure_threshold_used}
            </StatsValues>
          </GridItemFlex>
          <GridItemFlex>
            <TopBoxSubSubTitles color={'navy'}>
              1850x{' '}
              <span
                style={{
                  color: 'rgb(162, 186, 195)',
                }}>
                {' Increase'}
              </span>{' '}
            </TopBoxSubSubTitles>
          </GridItemFlex>
          <GridItemFlex>
            <TopBoxSubSubTitles color={'rgb(203,5,5)'}>
              56%{' '}
              <span
                style={{
                  color: 'rgb(162, 186, 195)',
                }}>
                {'Wastage'}
              </span>
            </TopBoxSubSubTitles>
          </GridItemFlex>
        </TopBoxGridContainer>
      </MiddleSection>
      {/*-------------------------------*/}
      <BottomSection>
        <div style={{ display: 'flex' }}>
          <div style={{ flexDirection: 'column', width: '34%' }}>
            <StatsMiddleAndBotBoxDescription>Repairs</StatsMiddleAndBotBoxDescription>
          </div>
          <div
            style={{
              flexDirection: 'column',
              fontSize: '16px',
              flex: '1',
              color: 'white',
              marginTop: '7px',
            }}>
            <span
              style={{
                color: 'rgb(162, 186, 195)',
              }}>
              {'Outage days required:'}
            </span>{' '}
            {repairStats[props.repairSelected].outage_days}
          </div>
        </div>
        <MiddleGridHolder>
          <MiddleBoxGridContainer>
            <GridItem>
              <StatsIcon src={tools_image} alt='repair logo' />
            </GridItem>
            <GridItem>
              <StatsMiddleboxTitles>Total Repair Area</StatsMiddleboxTitles>
              <StatsValues>
                {repairStats[props.repairSelected].total_repair_area.toLocaleString(
                  'en-US'
                )}{' '}
                sqft
              </StatsValues>
            </GridItem>
            <GridItem>
              <StatsMiddleboxSubtitles>
                <span
                  style={{
                    color: 'white',
                  }}>
                  {repairStats[props.repairSelected].sqft_overlay.toLocaleString('en-US')}{' '}
                  sqft
                </span>{' '}
                of Overlay
              </StatsMiddleboxSubtitles>
              <StatsMiddleboxSubtitles>
                <span
                  style={{
                    color: 'white',
                  }}>
                  {repairStats[props.repairSelected].sqft_panels.toLocaleString('en-US')}{' '}
                  sqft
                </span>{' '}
                of Panels
              </StatsMiddleboxSubtitles>
            </GridItem>
            <GridItem>
              <StatsIcon src={money_image} alt='cash logo' />
            </GridItem>
            <GridItem>
              <StatsMiddleboxTitles>Estimated Repair Cost</StatsMiddleboxTitles>
              <StatsValues>
                $
                {repairStats[props.repairSelected].estimated_repair_cost.toLocaleString(
                  'en-US'
                )}
              </StatsValues>
            </GridItem>
            <GridItem>
              <StatsMiddleboxSubtitles>
                <span
                  style={{
                    color: 'white',
                  }}>
                  $
                  {repairStats[props.repairSelected].cost_overlay.toLocaleString('en-US')}
                </span>{' '}
                in Overlay
              </StatsMiddleboxSubtitles>
              <StatsMiddleboxSubtitles>
                <span
                  style={{
                    color: 'white',
                  }}>
                  ${repairStats[props.repairSelected].cost_panels.toLocaleString('en-US')}
                </span>{' '}
                in Panels
              </StatsMiddleboxSubtitles>
            </GridItem>
          </MiddleBoxGridContainer>
        </MiddleGridHolder>
      </BottomSection>
    </>
  )
}
