import React, { useEffect } from 'react'
import { GetTokenSilentlyOptions, useAuth0, User } from '@auth0/auth0-react'
import { ReactComponent as UserIcon } from 'assets/user-icon.svg'
import { Auth0Audience } from '../api/helpers'
import styled from 'styled-components'
import { Button, Card } from 'gecko-ui'

function Profile() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0()
  const [showFallbackImg, setShowFallbackImg] = React.useState(false)
  const [accessToken, setAccessToken] = React.useState('Test')

  const getToken = async () => {
    const options = {
      audience: Auth0Audience(),
    }
    const { audience, scope } = options as GetTokenSilentlyOptions
    const accessToken = await getAccessTokenSilently({ audience, scope })
    return accessToken
  }
  useEffect(() => {
    getToken().then((data) => setAccessToken(data))
  }, [])

  const handleImgError: React.ReactEventHandler<HTMLImageElement> = (e) => {
    setShowFallbackImg(true)
  }

  const ImgDivStyled = styled.div`
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-top-width: 0.0625rem;
    border-color: #e5e7eb;

    @media (min-width: 40rem) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  `
  const ImgStyled = styled.img`
    width: 8rem;
    height: 8rem;
    border-radius: 625rem;
  `
  const UserIconStyled = styled(UserIcon)`
    width: 8rem;
    height: 8rem;
    border-radius: 625rem;
  `

  const TextDivStyled = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.25rem;
  `
  const HeaderTextStyled = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
  `
  const TextStyled = styled.p`
    font-size: 0.875rem;
    line-height: 1.25rem;
    word-break: break-all;
  `

  if (isLoading) {
    return <div>Loading(...)</div>
  }
  return isAuthenticated && !!user ? (
    <>
      <ImgDivStyled>
        {isAuthenticated && !isLoading && !showFallbackImg ? (
          <ImgStyled src={user?.picture} onError={handleImgError} alt='User profile' />
        ) : (
          <UserIconStyled />
        )}
      </ImgDivStyled>
      <Card>
        <TextDivStyled>
          <HeaderTextStyled>{user?.name}</HeaderTextStyled>
          <TextStyled>{user?.email}</TextStyled>
        </TextDivStyled>
        <TextDivStyled>
          <HeaderTextStyled>Access token:</HeaderTextStyled>
          <TextStyled>{accessToken}</TextStyled>
        </TextDivStyled>
      </Card>
      <Button
        buttonType='primary'
        onClick={() => logout({ returnTo: window.location.origin })}
        label='Log Out'
      />
    </>
  ) : null
}

export default Profile
