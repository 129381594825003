import {
  BottomSection,
  GridItem,
  GridItemFlex,
  IconRowContainer,
  MiddleGridHolder,
  MiddleSection,
  MiddleBoxGridContainer,
  StatsIcon,
  StatsMiddleAndBotBoxDescription,
  StatsMiddleboxSubtitles,
  StatsMiddleboxTitles,
  StatsTopboxTitle,
  StatsValues,
  TopBoxGridContainer,
  TopBoxSubSubTitles,
} from './DukeStyledComponents'
import tools_image from '../../../images/maintanence_transparent.png'
import money_image from '../../../images/Funds_Icon_Light_Green.png'
import React from 'react'
import { repairStats, RepairStatValues } from '../../../util/RepairStats'

export interface DukeMiddleGreenBoxesProps {}

export const DukeMiddleGreenBoxes: React.FC<DukeMiddleGreenBoxesProps> = (props) => {
  return (
    <>
      <MiddleSection>
        <TopBoxGridContainer>
          <GridItemFlex>
            <StatsTopboxTitle>Total Readings Captured</StatsTopboxTitle>
          </GridItemFlex>
          <GridItemFlex>
            <StatsTopboxTitle>Lowest Reading Found</StatsTopboxTitle>
          </GridItemFlex>
          <GridItemFlex>
            <StatsTopboxTitle>Failure Threshold Used</StatsTopboxTitle>
          </GridItemFlex>
          <GridItemFlex>
            <StatsValues style={{ marginTop: '20px' }}>{'3.94 M'}</StatsValues>
          </GridItemFlex>
          <GridItemFlex>
            <StatsValues style={{ marginTop: '20px' }}>{'0.085'}</StatsValues>
          </GridItemFlex>
          <GridItemFlex>
            <StatsValues style={{ marginTop: '20px' }}>0.07</StatsValues>
          </GridItemFlex>
          <GridItemFlex></GridItemFlex>
          <GridItemFlex>
            <TopBoxSubSubTitles color={'rgb(203,5,5)'}>
              62.9%{' '}
              <span
                style={{
                  color: 'rgb(162, 186, 195)',
                }}>
                {'Wastage'}
              </span>
            </TopBoxSubSubTitles>
          </GridItemFlex>
        </TopBoxGridContainer>
      </MiddleSection>
      {/*-------------------------------*/}
      <BottomSection>
        <div style={{ display: 'flex' }}>
          <div style={{ flexDirection: 'column', width: '34%' }}>
            <StatsMiddleAndBotBoxDescription>Repairs</StatsMiddleAndBotBoxDescription>
          </div>
          <div
            style={{
              flexDirection: 'column',
              fontSize: '16px',
              flex: '1',
              color: 'white',
              marginTop: '7px',
            }}>
            <span
              style={{
                color: 'rgb(162, 186, 195)',
              }}>
              {'Outage days required:'}
            </span>{' '}
            5
          </div>
        </div>
        <MiddleGridHolder>
          <MiddleBoxGridContainer>
            <GridItem>
              <IconRowContainer>
                <StatsIcon src={tools_image} alt='repair logo' />
                <StatsIcon src={money_image} alt='cash logo' />
              </IconRowContainer>
            </GridItem>
            <GridItem>
              <StatsMiddleboxTitles>Total Repair Area</StatsMiddleboxTitles>
              <StatsValues>43 sqft</StatsValues>
            </GridItem>
            <GridItem>
              <StatsMiddleboxTitles>Estimated Repair Cost</StatsMiddleboxTitles>
              <StatsValues>$43,075</StatsValues>
            </GridItem>
          </MiddleBoxGridContainer>
        </MiddleGridHolder>
      </BottomSection>
    </>
  )
}
