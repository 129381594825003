import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoggedInContent from './LoggedInContent'
import LoadingContent from './LoadingContent'
import { LoginPage } from 'gecko-ui'

function Content() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const url = window.location.href
  const inviteMatches = url.match(/invitation=([^&]+)/)
  const orgMatches = url.match(/organization=([^&]+)/)

  if (isLoading) {
    return <LoadingContent />
  }
  if (isAuthenticated) {
    return <LoggedInContent />
  } else if (inviteMatches && orgMatches) {
    loginWithRedirect({
      organization: orgMatches[1],
      invitation: inviteMatches[1],
    })
  }
  return <LoginPage />
}

export default Content
