// @ts-ignore
import Plotly from 'plotly.js/dist/plotly.js'

import { GeckoTheme } from 'gecko-ui'
import React, { useEffect } from 'react'
import {
  aesProcess20,
  aesProcess40,
  aesStandalone,
  base20Trace,
  base40Trace,
  baseStandalone,
  demoStandalone,
  high20Trace,
  high40Trace,
  highStandalone,
  historicalTrace,
  noActionTrace,
  trendLongTerm,
  currentTrendST,
} from '../../util/TimelineTraces'
import { useParams } from 'react-router-dom'

export interface timelineProps {
  width: number
  isDemo: boolean
}

export const Timeline: React.FC<timelineProps> = (props) => {
  const allData = [
    historicalTrace,
    noActionTrace,
    base40Trace,
    base20Trace,
    aesProcess40,
    aesProcess20,
    high40Trace,
    high20Trace,
  ]

  const data = [
    historicalTrace,
    noActionTrace,
    // aesStandalone,

    trendLongTerm,
    currentTrendST,
    baseStandalone,
    highStandalone,
  ]

  const demoData = [
    historicalTrace,
    noActionTrace,
    demoStandalone,
    baseStandalone,
    highStandalone,
  ]

  let { urlSuffix } = useParams()
  urlSuffix = urlSuffix as string

  let layout = {
    margin: { t: 0, b: 25, l: 50, r: 25 },
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    autosize: true,
    autorange: true,
    useResizeHandler: true,
    font: { color: GeckoTheme.colors.gray['200_white'] },
    hoverlabel: {
      bgcolor: GeckoTheme.colors.slate['300'],
      font: { color: GeckoTheme.colors.slate['700_base'] },
    },
    width: 475,
    height: 300,
    legend: {
      title: { text: 'Key', font: { size: 14 } },
      orientation: 'v',
      itemwidth: 1,
      font: { size: 10 },
    },
    xaxis: {
      automargin: true,
      type: 'date',
      gridcolor: GeckoTheme.colors.slate['600'],
      fixedrange: true,
      title: {
        text: 'Date',
        standoff: 10,
      },
    },
    yaxis: {
      fixedrange: true,
      gridcolor: GeckoTheme.colors.slate['600'],
      title: 'Outages',
    },
  }
  const config = {
    displayModeBar: false,
  }

  useEffect(() => {
    layout.width = props.width - 25
    Plotly.react('timeline', !props.isDemo ? data : demoData, layout, config)
  }, [props.width])

  return <div id={'timeline'}></div>
}
